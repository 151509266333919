
import Vue from 'vue';
import TopLeftCli from '@/assets/svg/overview/TopLeftCli.vue';
import TopRightUi from '@/assets/svg/overview/TopRightUi.vue';
import BottomUi from '@/assets/svg/overview/BottomUi.vue';

export default Vue.extend({
  name: 'UiOverview',
  components: {
    TopLeftCli,
    TopRightUi,
    BottomUi
  }
});
