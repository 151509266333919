
import Vue, { PropOptions } from 'vue';
import HeaderDivider from '@/modules/@common/components/HeaderDivider.vue';
import { Brand } from '@localazy/directus-service';
import FeaturedBrand from '@/modules/featured-brands/components/FeaturedBrand.vue';

export default Vue.extend({
  name: 'FeaturedBrands',
  components: {
    HeaderDivider,
    FeaturedBrand
  },
  props: {
    noFooter: {
      type: Boolean,
      default: false
    },
    brands: {
      type: Array,
      required: true
    } as PropOptions<Brand[]>
  }
});
